
.wrapper{
    z-index: 1010;
    position: absolute;

    margin: auto;
    overflow: auto;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);

    max-width: 700px;
    width: 90%;

}
.module{
    position: relative;

    display: block;
    height: auto;

}
.wrapper_module{
    position: static;
    display: table;

    z-index:1001;
    width: 100%;
    background: #000104;
    border: 2px solid #00F0FF;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: rgba(0, 240, 255, 0.7) 0 0 20px 2px;
    border-radius: 20px;
    height:auto;
    min-height:50vh;
    vertical-align: middle;


}
.content{
    top: 50%;
    display: table-cell;
    /*display:inline-block;*/
    width: 100%;
    height: 100%;
    vertical-align: middle;


}
.title_number_alone,
.title_number{
    margin-top: 5px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    text-align: center;

    color: #00F0FF;
}
.title_number_alone{
   padding-top: 10px;
    font-size: 32px;
}
.number_alone,
.number{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 250px;
    line-height: 260px;
    text-align: center;

    color: #00F0FF;
}
.number_alone{
    font-size: 301px;
    line-height: 370px;
}
.team_name_title,
.color_title{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    text-align: center;

    color: #00F0FF;
}
.team_name_title{
    margin-top: 20px;
}
.team_name,
.color_name{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    margin-bottom: 10px;
    color: #00F0FF;
}
.team_name{
    font-weight: bold;
    margin-top: 5px;
    font-size: 34px;
}
.color_value{
    display:block;
    margin:0 auto 15px auto ;

    width: 126px;
    height: 40px;
    position: relative;

    border-radius: 10px;

}

.btn_next_player{

    width: 126px;
    height: 50px;


    background: #000000;
    border: 2px solid #00F0FF;
    box-sizing: border-box;
    border-radius: 20px;


    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #00F0FF;
    display: block;
    margin: 0px auto 9px auto;

    cursor: pointer;


}